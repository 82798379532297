/* ------------------------------------------------------------------
/* Testimonials
/* ------------------------------------------------------------------ */

#testimonials {
  background: #1f1f1f url(/images/top_wallpaper.jpg) no-repeat center center;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-attachment: fixed;
  position: relative;
  min-height: 200px;
  width: 100%;
  overflow: hidden;
  .text-container {
    padding-top: 96px;
    padding-bottom: 66px;
  }
  h1 {
    font: 18px/24px "opensans-bold", sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
    span {
      display: none;
    }
  }
  .header-col {
    padding-top: 9px;
    h1:before {
      font-family: "FontAwesome";
      content: "\f10d";
      padding-right: 10px;
      font-size: 72px;
      line-height: 72px;
      text-align: left;
      float: left;
      color: #fff;
    }
  }
  blockquote {
    margin: 0 0px 30px 0px;
    padding-left: 0;
    position: relative;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 1);
    &:before {
      content: none;
    }
    p {
      font-family: "librebaskerville-italic", serif;
      padding: 0;
      font-size: 24px;
      line-height: 48px;
      color: #fff;
    }
    cite {
      display: block;
      font-size: 12px;
      font-style: normal;
      line-height: 18px;
      color: #fff;
      &:before {
        content: "\2014 \0020";
      }
      a {
        color: #8b9798;
        border: none;
        &:visited {
          color: #8b9798;
          border: none;
        }
      }
    }
  }
}

/*	Blockquotes */

/* Flex Slider
/* ------------------------------------------------------------------ */

/* Reset */

.flexslider a {
  &:active,
  &:focus {
    outline: none;
  }
}

.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.slides li {
  margin: 0;
  padding: 0;
}

/* Necessary Styles */

.flexslider {
  position: relative;
  zoom: 1;
  margin: 0;
  padding: 0;
  .slides {
    zoom: 1;
    > li {
      position: relative;
      display: none;
      -webkit-backface-visibility: hidden;
    }
  }
}

/* Hide the slides before the JS is loaded. Avoids image jumping */

/* Suggested container for slide animation setups. Can replace this with your own */

.flex-container {
  zoom: 1;
  position: relative;
}

/* Clearfix for .slides */

.slides {
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    content: " ";
    display: table;
    clear: both;
  }
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */

.no-js .slides > li:first-child {
  display: block;
}

/* Slider Styles */

.slides {
  zoom: 1;
  > li {
    /*margin-right: 5px; */
    overflow: hidden;
  }
}

/* Control Nav */

.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -20px;
  text-align: left;
  li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline;
  }
}

.flex-control-paging li a {
  width: 12px;
  height: 12px;
  display: block;
  background: #ddd;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.3);
  &:hover {
    background: #ccc;
    background: rgba(255, 255, 255, 0.7);
  }
  &.flex-active {
    background: #fff;
    background: rgba(255, 255, 255, 0.9);
    cursor: default;
  }
}
