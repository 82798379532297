body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ------------------------------------------------------------------ */
/* Header Styles
/* ------------------------------------------------------------------ */
header {
  position: relative;
  height: 800px;
  min-height: 500px;
  width: 100%;
  background: #161415 url(/images/top_wallpaper.jpg) no-repeat top center;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  text-align: center;
  overflow: hidden; }
  header:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%; }
  header .banner {
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    width: 85%;
    padding-bottom: 30px;
    text-align: center; }
  header .banner-text {
    width: 100%; }
    header .banner-text h1 {
      font: 90px/1.1em "Roboto", sans-serif;
      color: #fff;
      letter-spacing: -2px;
      margin: 0 auto 18px auto;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8); }
    header .banner-text h3 {
      font: 18px/1.9em "librebaskerville-regular", serif;
      color: #a8a8a8;
      margin: 0 auto;
      width: 70%;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5); }
      header .banner-text h3 span,
      header .banner-text h3 a {
        color: #fff; }
    header .banner-text hr {
      width: 60%;
      margin: 18px auto 24px auto;
      border-color: #2f2d2e;
      border-color: rgba(150, 150, 150, 0.1); }
  header .social {
    margin: 24px 0;
    padding: 0;
    font-size: 30px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8); }
    header .social li {
      display: inline-block;
      margin: 0 15px;
      padding: 0; }
      header .social li a {
        color: #fff; }
        header .social li a:hover {
          color: lightsteelblue; }

/* vertically center banner section */
/* header social links */
/* scrolldown link */
.scrolldown a {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 42px;
  line-height: 42px;
  color: #fff;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .scrolldown a:hover {
    color: lightsteelblue; }

/* primary navigation
--------------------------------------------------------------------- */
#nav-wrap {
  font-family: "Roboto";
  font-weight: 900;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0; }
  #nav-wrap ul,
  #nav-wrap li,
  #nav-wrap a {
    margin: 0;
    padding: 0;
    border: none;
    outline: none; }

/* nav-wrap */
.opaque {
  background-color: #333; }

/* hide toggle button */
#nav-wrap > a.mobile-btn {
  display: none; }

ul#nav {
  min-height: 48px;
  width: auto;
  /* center align the menu */
  text-align: center; }
  ul#nav li {
    position: relative;
    list-style: none;
    height: 48px;
    display: inline-block; }
    ul#nav li a {
      /* 8px padding top + 8px padding bottom + 32px line-height = 48px */
      display: inline-block;
      padding: 8px 13px;
      line-height: 32px;
      text-decoration: none;
      text-align: left;
      color: #fff;
      -webkit-transition: color 0.2s ease-in-out;
      transition: color 0.2s ease-in-out; }
      ul#nav li a:active {
        background-color: transparent !important; }
    ul#nav li.current a {
      color: lightsteelblue; }

/* Links */

/* ------------------------------------------------------------------ */
/* c. About Section
/* ------------------------------------------------------------------ */
#about {
  background: #2b2b2b;
  padding-top: 96px;
  padding-bottom: 66px;
  overflow: hidden; }
  #about a {
    color: #fff; }
    #about a:visited {
      color: #fff; }
    #about a:hover, #about a:focus {
      color: lightsteelblue; }
  #about h2 {
    font: 22px/30px "opensans-bold", sans-serif;
    color: #fff;
    margin-bottom: 12px; }
  #about p {
    line-height: 30px;
    color: #7a7a7a; }
  #about .profile-pic {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: white; }
  #about .contact-details {
    width: 41.66667%; }
  #about .download {
    width: 58.33333%;
    padding-top: 6px; }
  #about .main-col {
    padding-right: 5%; }
  #about .download .button {
    margin-top: 6px;
    background: #444; }
    #about .download .button:hover {
      background: #fff;
      color: #2b2b2b; }
    #about .download .button i {
      margin-right: 15px;
      font-size: 20px; }

/* ------------------------------------------------------------------ */
/* d. Resume Section
/* ------------------------------------------------------------------ */
#resume {
  background: #fff;
  padding-top: 90px;
  padding-bottom: 72px;
  overflow: hidden; }
  #resume a {
    color: darkblue; }
    #resume a:visited {
      color: darkblue; }
    #resume a:hover, #resume a:focus {
      color: darkblue; }
  #resume h1 {
    font: 18px/24px "Roboto", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px; }
    #resume h1 span {
      border-bottom: 3px solid darkblue;
      padding-bottom: 6px; }
  #resume h3 {
    font: 25px/30px "Roboto", sans-serif; }
  #resume .header-col {
    padding-top: 9px; }
  #resume .main-col {
    padding-right: 10%; }

.education,
.work {
  margin-bottom: 48px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e8e8e8; }

#resume .info {
  font: 16px/24px "Roboto", serif;
  color: #6e7881;
  margin-bottom: 18px;
  margin-top: 9px; }
  #resume .info span {
    margin-right: 5px;
    margin-left: 5px; }

#resume .date {
  font: 15px/24px "opensans-regular", sans-serif;
  margin-top: 6px; }

/*----------------------------------------------*/
/*	Skill Bars
/*----------------------------------------------*/
.bars {
  width: 95%;
  float: left;
  padding: 0;
  text-align: left; }
  .bars .skills {
    margin-top: 6px;
    list-style: none; }
  .bars li {
    position: relative;
    margin-bottom: 6px;
    background: #ccc;
    height: 42px;
    border-radius: 3px; }
    .bars li .skill-anim {
      font: 15px "Roboto", sans-serif;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: normal;
      position: relative;
      left: 3px;
      z-index: 999; }

.bar-expand {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding-right: 24px;
  background: darkblue;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  border-radius: 3px 0 0 3px; }

.bootstrap {
  width: 85%;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.5)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
  -moz-animation: bootstrap 2s ease;
  -webkit-animation: bootstrap 2s ease; }

.scss {
  width: 72%;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.5)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
  -moz-animation: scss 2s ease;
  -webkit-animation: scss 2s ease; }

.cinema4d {
  width: 66%;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.5)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
  -moz-animation: cinema4d 2s ease;
  -webkit-animation: cinema4d 2s ease; }

.photoshop {
  width: 38%;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.5)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
  -moz-animation: photoshop 2s ease;
  -webkit-animation: photoshop 2s ease; }

.illustrator {
  width: 55%;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.5)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
  -moz-animation: illustrator 2s ease;
  -webkit-animation: illustrator 2s ease; }

.css {
  width: 90%;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.5)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
  -moz-animation: css 2s ease;
  -webkit-animation: css 2s ease; }

.html5 {
  width: 90%;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.5)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
  -moz-animation: html5 2s ease;
  -webkit-animation: html5 2s ease; }

.jquery {
  width: 60%;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.5)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
  -moz-animation: jquery 2s ease;
  -webkit-animation: jquery 2s ease; }

.javascript {
  width: 55%;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.5)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
  -moz-animation: javascript 2s ease;
  -webkit-animation: javascript 2s ease; }

.reactjs {
  width: 32%;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.5)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
  -moz-animation: reactjs 2s ease;
  -webkit-animation: reactjs 2s ease; }

.php {
  width: 30%;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.5)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
  -moz-animation: php 2s ease;
  -webkit-animation: php 2s ease; }

.mysql {
  width: 70%;
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.5)));
  background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5));
  -moz-animation: mysql 2s ease;
  -webkit-animation: mysql 2s ease; }

@-webkit-keyframes scss {
  0% {
    width: 0px; }
  100% {
    width: 72%; } }

@-webkit-keyframes cinema4d {
  0% {
    width: 0px; }
  100% {
    width: 66%; } }

@-webkit-keyframes mysql {
  0% {
    width: 0px; }
  100% {
    width: 70%; } }

@-webkit-keyframes php {
  0% {
    width: 0px; }
  100% {
    width: 30%; } }

@-webkit-keyframes reactjs {
  0% {
    width: 0px; }
  100% {
    width: 32%; } }

@-webkit-keyframes javascript {
  0% {
    width: 0px; }
  100% {
    width: 55%; } }

@-webkit-keyframes bootstrap {
  0% {
    width: 0px; }
  100% {
    width: 85%; } }

@-webkit-keyframes photoshop {
  0% {
    width: 0px; }
  100% {
    width: 38%; } }

@-webkit-keyframes illustrator {
  0% {
    width: 0px; }
  100% {
    width: 55%; } }

@-webkit-keyframes css {
  0% {
    width: 0px; }
  100% {
    width: 90%; } }

@-webkit-keyframes html5 {
  0% {
    width: 0px; }
  100% {
    width: 90%; } }

@-webkit-keyframes jquery {
  0% {
    width: 0px; }
  100% {
    width: 60%; } }

/* ------------------------------------------------------------------ */
/* Portfolio Section
/* ------------------------------------------------------------------ */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto; }

h1 {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 48px;
  color: rgba(66, 65, 66, 0.9); }

h5 {
  color: rgba(66, 65, 66, 0.9); }

h2 {
  color: #fff;
  font-weight: 100; }

#portfolio {
  background: #ebeeee;
  padding-top: 90px;
  padding-bottom: 60px; }

.maxWidth {
  width: 25%;
  max-width: 150px;
  min-width: 80px;
  height: auto;
  padding: 6px; }

/* Portfolio Content */
#portfolio-wrapper .columns {
  margin-bottom: 36px; }

.portfolio-item .item-wrap {
  background: #fff;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }
  .portfolio-item .item-wrap a {
    display: block;
    cursor: pointer; }
  .portfolio-item .item-wrap .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -moz-opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    background: rgba(0, 0, 0, 0.85); }
  .portfolio-item .item-wrap .link-icon {
    display: block;
    color: #fff;
    height: 30px;
    width: 30px;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    opacity: 0;
    -moz-opacity: 0;
    filter: alpha(opacity=0);
    -webkit-transition: opacity 0.3s ease-in-out;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -15px;
    margin-top: -15px; }
  .portfolio-item .item-wrap img {
    vertical-align: bottom; }

.portfolio-item .portfolio-item-meta {
  padding: 18px; }
  .portfolio-item .portfolio-item-meta h5 {
    color: #fff; }
  .portfolio-item .portfolio-item-meta p {
    font-family: Roboto;
    font-size: 24px;
    color: #fff;
    margin: 0px 6px 10px; }

.portfolio-item:hover .overlay,
.portfolio-item:hover .link-icon {
  opacity: 1;
  -moz-opacity: 1;
  filter: alpha(opacity=100); }

/* overlay */
/* on hover */
/* popup modal */
.popup-modal {
  max-width: 550px;
  background: #fff;
  position: relative;
  margin: 0 auto; }
  .popup-modal .description-box {
    padding: 12px 36px 18px 36px; }
    .popup-modal .description-box h4 {
      font: 15px/24px "opensans-bold", sans-serif;
      margin-bottom: 12px;
      color: #111; }
    .popup-modal .description-box p {
      font: 14px/24px "opensans-regular", sans-serif;
      color: #a1a1a1;
      margin-bottom: 12px; }
    .popup-modal .description-box .categories {
      font: 11px/21px "opensans-light", sans-serif;
      color: #a1a1a1;
      text-transform: uppercase;
      letter-spacing: 2px;
      display: block;
      text-align: left; }
      .popup-modal .description-box .categories i {
        margin-right: 8px; }
  .popup-modal .link-box {
    padding: 18px 36px;
    background: #111;
    text-align: left; }
    .popup-modal .link-box a {
      color: #fff;
      font: 11px/21px "opensans-bold", sans-serif;
      text-transform: uppercase;
      letter-spacing: 3px;
      cursor: pointer; }
  .popup-modal a:hover {
    color: #00cccc; }
  .popup-modal a.popup-modal-dismiss {
    margin-left: 24px; }

/* fadein/fadeout effect for modal popup
/* ------------------------------------------------------------------ */
/* content at start */
.mfp-fade.mfp-wrap .mfp-content .popup-modal {
  opacity: 0;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out; }

.mfp-fade.mfp-wrap.mfp-ready .mfp-content .popup-modal {
  opacity: 1; }

.mfp-fade.mfp-wrap.mfp-removing .mfp-content .popup-modal {
  opacity: 0; }

/* content fadein */
/* content fadeout */

/* ------------------------------------------------------------------
/* Testimonials
/* ------------------------------------------------------------------ */
#testimonials {
  background: #1f1f1f url(/images/top_wallpaper.jpg) no-repeat center center;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-attachment: fixed;
  position: relative;
  min-height: 200px;
  width: 100%;
  overflow: hidden; }
  #testimonials .text-container {
    padding-top: 96px;
    padding-bottom: 66px; }
  #testimonials h1 {
    font: 18px/24px "opensans-bold", sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff; }
    #testimonials h1 span {
      display: none; }
  #testimonials .header-col {
    padding-top: 9px; }
    #testimonials .header-col h1:before {
      font-family: "FontAwesome";
      content: "\F10D";
      padding-right: 10px;
      font-size: 72px;
      line-height: 72px;
      text-align: left;
      float: left;
      color: #fff; }
  #testimonials blockquote {
    margin: 0 0px 30px 0px;
    padding-left: 0;
    position: relative;
    text-shadow: 0px 1px 3px black; }
    #testimonials blockquote:before {
      content: none; }
    #testimonials blockquote p {
      font-family: "librebaskerville-italic", serif;
      padding: 0;
      font-size: 24px;
      line-height: 48px;
      color: #fff; }
    #testimonials blockquote cite {
      display: block;
      font-size: 12px;
      font-style: normal;
      line-height: 18px;
      color: #fff; }
      #testimonials blockquote cite:before {
        content: "\2014    "; }
      #testimonials blockquote cite a {
        color: #8b9798;
        border: none; }
        #testimonials blockquote cite a:visited {
          color: #8b9798;
          border: none; }

/*	Blockquotes */
/* Flex Slider
/* ------------------------------------------------------------------ */
/* Reset */
.flexslider a:active, .flexslider a:focus {
  outline: none; }

.slides,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none; }

.slides li {
  margin: 0;
  padding: 0; }

/* Necessary Styles */
.flexslider {
  position: relative;
  zoom: 1;
  margin: 0;
  padding: 0; }
  .flexslider .slides {
    zoom: 1; }
    .flexslider .slides > li {
      position: relative;
      display: none;
      -webkit-backface-visibility: hidden; }

/* Hide the slides before the JS is loaded. Avoids image jumping */
/* Suggested container for slide animation setups. Can replace this with your own */
.flex-container {
  zoom: 1;
  position: relative; }

/* Clearfix for .slides */
.slides:before {
  content: " ";
  display: table; }

.slides:after {
  content: " ";
  display: table;
  clear: both; }

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block; }

/* Slider Styles */
.slides {
  zoom: 1; }
  .slides > li {
    /*margin-right: 5px; */
    overflow: hidden; }

/* Control Nav */
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -20px;
  text-align: left; }
  .flex-control-nav li {
    margin: 0 6px;
    display: inline-block;
    zoom: 1;
    *display: inline; }

.flex-control-paging li a {
  width: 12px;
  height: 12px;
  display: block;
  background: #ddd;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  text-indent: -9999px;
  border-radius: 20px;
  box-shadow: inset 0 0 3px rgba(255, 255, 255, 0.3); }
  .flex-control-paging li a:hover {
    background: #ccc;
    background: rgba(255, 255, 255, 0.7); }
  .flex-control-paging li a.flex-active {
    background: #fff;
    background: rgba(255, 255, 255, 0.9);
    cursor: default; }

/* ------------------------------------------------------------------ */
/* Contact Section
/* ------------------------------------------------------------------ */
.gMaps {
  width: 100%;
  height: 300px; }

#contact {
  background: #191919;
  padding-top: 96px;
  padding-bottom: 102px;
  color: #636363; }
  #contact .section-head {
    margin-bottom: 42px; }
  #contact a {
    color: lightsteelblue; }
    #contact a:visited {
      color: lightsteelblue; }
    #contact a:hover, #contact a:focus {
      color: #fff; }
  #contact h1 {
    font-family: Roboto;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #ebeeee;
    margin-bottom: 6px; }
    #contact h1 span {
      display: none; }
    #contact h1:before {
      font-family: "FontAwesome";
      content: "\F0E0";
      padding-right: 10px;
      font-size: 72px;
      line-height: 72px;
      text-align: left;
      float: left;
      color: #ebeeee; }
  #contact h4 {
    font-family: Roboto;
    color: #ebeeee;
    margin-bottom: 6px; }
  #contact p.lead {
    font-family: Roboto;
    padding-right: 3%; }
  #contact .header-col {
    padding-top: 6px; }
  #contact form {
    margin-bottom: 30px; }
  #contact label {
    font-family: Roboto;
    margin: 12px 0;
    color: #ebeeee;
    display: inline-block;
    float: left;
    width: 26%; }
  #contact input,
  #contact textarea,
  #contact select {
    padding: 18px 20px;
    color: #eee;
    background: #373233;
    margin-bottom: 42px;
    border: 0;
    outline: none;
    font-size: 15px;
    line-height: 24px;
    width: 65%; }
  #contact input:focus,
  #contact textarea:focus,
  #contact select:focus {
    color: #fff;
    background-color: lightsteelblue; }
  #contact button.submit {
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
    background: #0d0d0d;
    border: none;
    cursor: pointer;
    height: auto;
    display: inline-block;
    border-radius: 3px;
    margin-left: 26%; }
    #contact button.submit:hover {
      color: #0d0d0d;
      background: #fff; }
  #contact span.required {
    color: lightsteelblue;
    font-size: 13px; }

/* ------------------------------------------------------------------ */
/* Footer
/* ------------------------------------------------------------------ */
footer {
  padding-top: 48px;
  margin-bottom: 48px;
  color: #303030;
  font-size: 14px;
  text-align: center;
  position: relative; }
  footer a {
    color: #525252; }
    footer a:visited {
      color: #525252; }
    footer a:hover, footer a:focus {
      color: lightsteelblue; }
  footer .copyright {
    margin: 0;
    padding: 0; }
    footer .copyright li {
      display: inline-block;
      margin: 0;
      padding: 0;
      line-height: 24px; }

/* copyright */
.ie footer .copyright li {
  display: inline; }

footer .copyright li:before {
  content: "\2022";
  padding-left: 10px;
  padding-right: 10px;
  color: lightsteelblue; }

footer .copyright li:first-child:before {
  display: none; }

footer .social-links {
  margin: 18px 0 30px 0;
  padding: 0;
  font-size: 30px; }
  footer .social-links li {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-left: 42px;
    color: #f06000; }
    footer .social-links li:first-child {
      margin-left: 0; }

/* social links */
/* Go To Top Button */
#go-top {
  position: absolute;
  top: -24px;
  left: 50%;
  margin-left: -30px; }
  #go-top a {
    text-decoration: none;
    border: 0 none;
    display: block;
    width: 60px;
    height: 60px;
    background-color: #525252;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #fff;
    font-size: 21px;
    line-height: 60px;
    border-radius: 100%; }
    #go-top a:hover {
      background-color: lightsteelblue; }

/* ------------------------------------------------------------------ */
/*  General Styles
/* ------------------------------------------------------------------ */
body {
  background: #0f0f0f; }

/* ------------------------------------------------------------------ */
/* Call To Action Section
/* ------------------------------------------------------------------ */
#call-to-action {
  background: #212121;
  padding-top: 66px;
  padding-bottom: 48px; }
  #call-to-action h1 {
    font: 18px/24px "opensans-bold", sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff; }
    #call-to-action h1 span {
      display: none; }
  #call-to-action .header-col h1:before {
    font-family: "FontAwesome";
    content: "\F0AC";
    padding-right: 10px;
    font-size: 72px;
    line-height: 72px;
    text-align: left;
    float: left;
    color: #fff; }
  #call-to-action .action {
    margin-top: 12px; }
  #call-to-action h2 {
    font: 28px/36px "opensans-bold", sans-serif;
    color: #ebeeee;
    margin-bottom: 6px; }
    #call-to-action h2 a {
      color: inherit; }
  #call-to-action p {
    color: #636363;
    font-size: 17px; }
  #call-to-action .button:hover, #call-to-action .button:active {
    background: #ffffff;
    color: #0d0d0d; }
  #call-to-action p span {
    font-family: "opensans-semibold", sans-serif;
    color: #d8d8d8; }

/*#
call-to-action .button {
	color:#fff;
   background: #0D0D0D;
}
*/
/* contact form */
#message-warning,
#message-success {
  display: none;
  background: #0f0f0f;
  padding: 24px 24px;
  margin-bottom: 36px;
  width: 65%;
  margin-left: 26%; }

#message-warning {
  color: #d72828; }

#message-success {
  color: #11abb0; }

#message-warning i,
#message-success i {
  margin-right: 10px; }

#image-loader {
  display: none;
  position: relative;
  left: 18px;
  top: 17px; }

/* Twitter Feed */
#twitter {
  margin-top: 12px;
  padding: 0; }
  #twitter li {
    margin: 6px 0px 12px 0;
    line-height: 30px; }
    #twitter li span {
      display: block; }
    #twitter li b a {
      font: 13px/36px "opensans-regular", Sans-serif;
      color: #474747 !important;
      border: none; }

