/* ------------------------------------------------------------------ */
/* Footer
/* ------------------------------------------------------------------ */

@import "../../scss/colours.scss";
footer {
  padding-top: 48px;
  margin-bottom: 48px;
  color: #303030;
  font-size: 14px;
  text-align: center;
  position: relative;
  a {
    color: #525252;
    &:visited {
      color: #525252;
    }
    &:hover,
    &:focus {
      color: $txt-hover;
    }
  }
  .copyright {
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin: 0;
      padding: 0;
      line-height: 24px;
    }
  }
}

/* copyright */

.ie footer .copyright li {
  display: inline;
}

footer {
  .copyright li {
    &:before {
      content: "\2022";
      padding-left: 10px;
      padding-right: 10px;
      color: $txt-hover;
    }
    &:first-child:before {
      display: none;
    }
  }
  .social-links {
    margin: 18px 0 30px 0;
    padding: 0;
    font-size: 30px;
    li {
      display: inline-block;
      margin: 0;
      padding: 0;
      margin-left: 42px;
      color: #f06000;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}

/* social links */

/* Go To Top Button */

#go-top {
  position: absolute;
  top: -24px;
  left: 50%;
  margin-left: -30px;
  a {
    text-decoration: none;
    border: 0 none;
    display: block;
    width: 60px;
    height: 60px;
    background-color: #525252;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    color: #fff;
    font-size: 21px;
    line-height: 60px;
    border-radius: 100%;
    &:hover {
      background-color: $txt-hover;
    }
  }
}
