// colours for site

$btn-warning: red;
$bkg-header: #161415;
$txt-hover: lightsteelblue;
$lvl-bars: darkblue;
$linear: linear-gradient(
  to left,
  rgba(255, 255, 255, 0),
  rgba(255, 255, 255, 0.5)
);
