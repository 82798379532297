/* ------------------------------------------------------------------ */
/*  General Styles
/* ------------------------------------------------------------------ */

body {
  background: #0f0f0f;
}

/* ------------------------------------------------------------------ */
/* Call To Action Section
/* ------------------------------------------------------------------ */

#call-to-action {
  background: #212121;
  padding-top: 66px;
  padding-bottom: 48px;
  h1 {
    font: 18px/24px "opensans-bold", sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
    span {
      display: none;
    }
  }
  .header-col h1:before {
    font-family: "FontAwesome";
    content: "\f0ac";
    padding-right: 10px;
    font-size: 72px;
    line-height: 72px;
    text-align: left;
    float: left;
    color: #fff;
  }
  .action {
    margin-top: 12px;
  }
  h2 {
    font: 28px/36px "opensans-bold", sans-serif;
    color: #ebeeee;
    margin-bottom: 6px;
    a {
      color: inherit;
    }
  }
  p {
    color: #636363;
    font-size: 17px;
  }
  .button {
    &:hover,
    &:active {
      background: #ffffff;
      color: #0d0d0d;
    }
  }
  p span {
    font-family: "opensans-semibold", sans-serif;
    color: #d8d8d8;
  }
}

/*#
call-to-action .button {
	color:#fff;
   background: #0D0D0D;
}
*/

/* contact form */

#message-warning,
#message-success {
  display: none;
  background: #0f0f0f;
  padding: 24px 24px;
  margin-bottom: 36px;
  width: 65%;
  margin-left: 26%;
}

#message-warning {
  color: #d72828;
}

#message-success {
  color: #11abb0;
}

#message-warning i,
#message-success i {
  margin-right: 10px;
}

#image-loader {
  display: none;
  position: relative;
  left: 18px;
  top: 17px;
}

/* Twitter Feed */

#twitter {
  margin-top: 12px;
  padding: 0;
  li {
    margin: 6px 0px 12px 0;
    line-height: 30px;
    span {
      display: block;
    }
    b a {
      font: 13px/36px "opensans-regular", Sans-serif;
      color: #474747 !important;
      border: none;
    }
  }
}
