/* ------------------------------------------------------------------ */
/* d. Resume Section
/* ------------------------------------------------------------------ */
@import "../../scss/colours";

#resume {
  background: #fff;
  padding-top: 90px;
  padding-bottom: 72px;
  overflow: hidden;
  a {
    color: $lvl-bars;
    &:visited {
      color: $lvl-bars;
    }
    &:hover,
    &:focus {
      color: $lvl-bars;
    }
  }
  h1 {
    font: 18px/24px "Roboto", sans-serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    span {
      border-bottom: 3px solid $lvl-bars;
      padding-bottom: 6px;
    }
  }
  h3 {
    font: 25px/30px "Roboto", sans-serif;
  }
  .header-col {
    padding-top: 9px;
  }
  .main-col {
    padding-right: 10%;
  }
}

.education,
.work {
  margin-bottom: 48px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e8e8e8;
}

#resume {
  .info {
    font: 16px/24px "Roboto", serif;
    color: #6e7881;
    margin-bottom: 18px;
    margin-top: 9px;
    span {
      margin-right: 5px;
      margin-left: 5px;
    }
  }
  .date {
    font: 15px/24px "opensans-regular", sans-serif;
    margin-top: 6px;
  }
}

/*----------------------------------------------*/
/*	Skill Bars
/*----------------------------------------------*/

.bars {
  width: 95%;
  float: left;
  padding: 0;
  text-align: left;
  .skills {
    margin-top: 6px;
    list-style: none;
  }
  li {
    position: relative;
    margin-bottom: 6px;
    background: #ccc;
    height: 42px;
    border-radius: 3px;
    .skill-anim {
      font: 15px "Roboto", sans-serif;
      color: #fff;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-weight: normal;
      position: relative;
      left: 3px;
      z-index: 999;
      //top: -36px;
    }
  }
}

.bar-expand {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding-right: 24px;
  background: $lvl-bars;
  display: inline-block;
  height: 42px;
  line-height: 42px;
  border-radius: 3px 0 0 3px;
}

// level progress
$lvl-bootstrap: 85%;
$lvl-cinema: 60%;
$lvl-css: 90%;
$lvl-scss: 76%;
$lvl-html: 90%;
$lvl-illustrator: 55%;
$lvl-js: 55%;
$lvl-jquery: 60%;
$lvl-mysql: 70%;
$lvl-njs: 60%;
$lvl-photoshop: 38%;
$lvl-php: 30%;
$lvl-react: 32%;
$lvl-scss: 72%;
$lvl-cinema4d: 66%;

.bootstrap {
  width: $lvl-bootstrap;
  background-image: $linear;
  -moz-animation: bootstrap 2s ease;
  -webkit-animation: bootstrap 2s ease;
}
.scss {
  width: $lvl-scss;
  background-image: $linear;
  -moz-animation: scss 2s ease;
  -webkit-animation: scss 2s ease;
}
.cinema4d {
  width: $lvl-cinema4d;
  background-image: $linear;
  -moz-animation: cinema4d 2s ease;
  -webkit-animation: cinema4d 2s ease;
}
.photoshop {
  width: $lvl-photoshop;
  background-image: $linear;
  -moz-animation: photoshop 2s ease;
  -webkit-animation: photoshop 2s ease;
}

.illustrator {
  width: $lvl-illustrator;
  background-image: $linear;
  -moz-animation: illustrator 2s ease;
  -webkit-animation: illustrator 2s ease;
}

.css {
  width: $lvl-css;
  background-image: $linear;
  -moz-animation: css 2s ease;
  -webkit-animation: css 2s ease;
}

.html5 {
  width: $lvl-html;
  background-image: $linear;
  -moz-animation: html5 2s ease;
  -webkit-animation: html5 2s ease;
}

.jquery {
  width: $lvl-jquery;
  background-image: $linear;
  -moz-animation: jquery 2s ease;
  -webkit-animation: jquery 2s ease;
}
.javascript {
  width: $lvl-js;
  background-image: $linear;
  -moz-animation: javascript 2s ease;
  -webkit-animation: javascript 2s ease;
}
.reactjs {
  width: $lvl-react;
  background-image: $linear;
  -moz-animation: reactjs 2s ease;
  -webkit-animation: reactjs 2s ease;
}
.php {
  width: $lvl-php;
  background-image: $linear;
  -moz-animation: php 2s ease;
  -webkit-animation: php 2s ease;
}

.mysql {
  width: $lvl-mysql;
  background-image: $linear;
  -moz-animation: mysql 2s ease;
  -webkit-animation: mysql 2s ease;
}

@-moz-keyframes bootstrap {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-bootstrap;
  }
}
@-moz-keyframes javascript {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-js;
  }
}

@-moz-keyframes photoshop {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-photoshop;
  }
}

@-moz-keyframes illustrator {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-illustrator;
  }
}

@-moz-keyframes css {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-css;
  }
}

@-moz-keyframes html5 {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-html;
  }
}

@-moz-keyframes jquery {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-jquery;
  }
}
@-moz-keyframes javascript {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-js;
  }
}

@-moz-keyframes reactjs {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-react;
  }
}

@-moz-keyframes php {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-php;
  }
}
@-moz-keyframes mysql {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-mysql;
  }
}
@-moz-keyframes cinema4d {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-cinema4d;
  }
}
@-moz-keyframes scss {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-scss;
  }
}
@-webkit-keyframes scss {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-scss;
  }
}
@-webkit-keyframes cinema4d {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-cinema4d;
  }
}
@-webkit-keyframes mysql {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-mysql;
  }
}

@-webkit-keyframes php {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-php;
  }
}

@-webkit-keyframes reactjs {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-react;
  }
}

@-webkit-keyframes javascript {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-js;
  }
}

@-webkit-keyframes bootstrap {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-bootstrap;
  }
}

@-webkit-keyframes photoshop {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-photoshop;
  }
}

@-webkit-keyframes illustrator {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-illustrator;
  }
}

@-webkit-keyframes css {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-css;
  }
}

@-webkit-keyframes html5 {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-html;
  }
}

@-webkit-keyframes jquery {
  0% {
    width: 0px;
  }

  100% {
    width: $lvl-jquery;
  }
}
