/* ------------------------------------------------------------------ */
/* Contact Section
/* ------------------------------------------------------------------ */
@import "../../scss/colours";

.gMaps {
  width: 100%;
  height: 300px;
}

#contact {
  background: #191919;
  padding-top: 96px;
  padding-bottom: 102px;
  color: #636363;
  .section-head {
    margin-bottom: 42px;
  }
  a {
    color: $txt-hover;
    &:visited {
      color: $txt-hover;
    }
    &:hover,
    &:focus {
      color: #fff;
    }
  }
  h1 {
    font-family: Roboto;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #ebeeee;
    margin-bottom: 6px;
    span {
      display: none;
    }
    &:before {
      font-family: "FontAwesome";
      content: "\f0e0";
      padding-right: 10px;
      font-size: 72px;
      line-height: 72px;
      text-align: left;
      float: left;
      color: #ebeeee;
    }
  }
  h4 {
    font-family: Roboto;
    color: #ebeeee;
    margin-bottom: 6px;
  }
  p.lead {
    font-family: Roboto;
    padding-right: 3%;
  }
  .header-col {
    padding-top: 6px;
  }
  form {
    margin-bottom: 30px;
  }
  label {
    font-family: Roboto;
    margin: 12px 0;
    color: #ebeeee;
    display: inline-block;
    float: left;
    width: 26%;
  }
  input,
  textarea,
  select {
    padding: 18px 20px;
    color: #eee;
    background: #373233;
    margin-bottom: 42px;
    border: 0;
    outline: none;
    font-size: 15px;
    line-height: 24px;
    width: 65%;
  }
  input:focus,
  textarea:focus,
  select:focus {
    color: #fff;
    background-color: $txt-hover;
  }
  button.submit {
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
    background: #0d0d0d;
    border: none;
    cursor: pointer;
    height: auto;
    display: inline-block;
    border-radius: 3px;
    margin-left: 26%;
    &:hover {
      color: #0d0d0d;
      background: #fff;
    }
  }
  span.required {
    color: $txt-hover;
    font-size: 13px;
  }
}
