/* ------------------------------------------------------------------ */
/* Portfolio Section
/* ------------------------------------------------------------------ */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: Roboto;
}

h1 {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-bottom: 48px;
  color: rgba(66, 65, 66, 0.9);
}
h5 {
  color: rgba(66, 65, 66, 0.9);
}
h2 {
  color: #fff;
  font-weight: 100;
}
#portfolio {
  background: #ebeeee;
  padding-top: 90px;
  padding-bottom: 60px;
}

.maxWidth {
  width: 25%;
  max-width: 150px;
  min-width: 80px;
  height: auto;
  padding: 6px;
}
/* Portfolio Content */

#portfolio-wrapper .columns {
  margin-bottom: 36px;
}

.portfolio-item {
  .item-wrap {
    background: #fff;
    overflow: hidden;
    position: relative;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    a {
      display: block;
      cursor: pointer;
    }
    .overlay {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      -moz-opacity: 0;
      filter: alpha(opacity = 0);
      -webkit-transition: opacity 0.3s ease-in-out;
      -moz-transition: opacity 0.3s ease-in-out;
      -o-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;
      //background: url(/images/overlay-bg.png) repeat;
      background: rgba(0, 0, 0, 0.85);
    }

    .link-icon {
      display: block;
      color: #fff;
      height: 30px;
      width: 30px;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      opacity: 0;
      -moz-opacity: 0;
      filter: alpha(opacity = 0);
      -webkit-transition: opacity 0.3s ease-in-out;
      -moz-transition: opacity 0.3s ease-in-out;
      -o-transition: opacity 0.3s ease-in-out;
      transition: opacity 0.3s ease-in-out;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -15px;
      margin-top: -15px;
    }
    img {
      vertical-align: bottom;
    }
  }
  .portfolio-item-meta {
    padding: 18px;
    h5 {
      color: #fff;
    }
    p {
      font-family: Roboto;
      font-size: 24px;
      color: #fff;
      margin: 0px 6px 10px;
    }
  }
  &:hover {
    .overlay,
    .link-icon {
      opacity: 1;
      -moz-opacity: 1;
      filter: alpha(opacity = 100);
    }
  }
}

/* overlay */

/* on hover */

/* popup modal */

.popup-modal {
  max-width: 550px;
  background: #fff;
  position: relative;
  margin: 0 auto;
  .description-box {
    padding: 12px 36px 18px 36px;
    h4 {
      font: 15px/24px "opensans-bold", sans-serif;
      margin-bottom: 12px;
      color: #111;
    }
    p {
      font: 14px/24px "opensans-regular", sans-serif;
      color: #a1a1a1;
      margin-bottom: 12px;
    }
    .categories {
      font: 11px/21px "opensans-light", sans-serif;
      color: #a1a1a1;
      text-transform: uppercase;
      letter-spacing: 2px;
      display: block;
      text-align: left;
      i {
        margin-right: 8px;
      }
    }
  }
  .link-box {
    padding: 18px 36px;
    background: #111;
    text-align: left;
    a {
      color: #fff;
      font: 11px/21px "opensans-bold", sans-serif;
      text-transform: uppercase;
      letter-spacing: 3px;
      cursor: pointer;
    }
  }
  a {
    &:hover {
      color: #00cccc;
    }
    &.popup-modal-dismiss {
      margin-left: 24px;
    }
  }
}

/* fadein/fadeout effect for modal popup
/* ------------------------------------------------------------------ */

/* content at start */

.mfp-fade.mfp-wrap {
  .mfp-content .popup-modal {
    opacity: 0;
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
  }
  &.mfp-ready .mfp-content .popup-modal {
    opacity: 1;
  }
  &.mfp-removing .mfp-content .popup-modal {
    opacity: 0;
  }
}

/* content fadein */

/* content fadeout */
