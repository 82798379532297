@import "../../scss/colours";

/* ------------------------------------------------------------------ */
/* Header Styles
/* ------------------------------------------------------------------ */

header {
  position: relative;
  height: 800px;
  min-height: 500px;
  width: 100%;
  background: $bkg-header url(/images/top_wallpaper.jpg) no-repeat top center;
  background-size: cover !important;
  -webkit-background-size: cover !important;
  text-align: center;
  overflow: hidden;
  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  .banner {
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    width: 85%;
    padding-bottom: 30px;
    text-align: center;
  }
  .banner-text {
    width: 100%;
    h1 {
      font: 90px/1.1em "Roboto", sans-serif;
      color: #fff;

      letter-spacing: -2px;
      margin: 0 auto 18px auto;
      text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
    }
    h3 {
      font: 18px/1.9em "librebaskerville-regular", serif;
      color: #a8a8a8;
      margin: 0 auto;
      width: 70%;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
      span,
      a {
        color: #fff;
      }
    }
    hr {
      width: 60%;
      margin: 18px auto 24px auto;
      border-color: #2f2d2e;
      border-color: rgba(150, 150, 150, 0.1);
    }
  }
  .social {
    margin: 24px 0;
    padding: 0;
    font-size: 30px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
    li {
      display: inline-block;
      margin: 0 15px;
      padding: 0;
      a {
        color: #fff;
        &:hover {
          color: $txt-hover;
        }
      }
    }
  }
}

/* vertically center banner section */

/* header social links */

/* scrolldown link */
.scrolldown a {
  position: absolute;
  bottom: 30px;
  left: 50%;
  margin-left: -29px;
  color: #fff;
  display: block;
  height: 42px;
  width: 42px;
  font-size: 42px;
  line-height: 42px;
  color: #fff;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: $txt-hover;
  }
}
/* primary navigation
--------------------------------------------------------------------- */

#nav-wrap {
  ul,
  li,
  a {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }
  font-family: "Roboto";
  font-weight: 900;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
}

/* nav-wrap */

.opaque {
  background-color: #333;
}

/* hide toggle button */

#nav-wrap > a.mobile-btn {
  display: none;
}

ul#nav {
  min-height: 48px;
  width: auto;
  /* center align the menu */
  text-align: center;
  li {
    position: relative;
    list-style: none;
    height: 48px;
    display: inline-block;
    a {
      /* 8px padding top + 8px padding bottom + 32px line-height = 48px */
      display: inline-block;
      padding: 8px 13px;
      line-height: 32px;
      text-decoration: none;
      text-align: left;
      color: #fff;
      -webkit-transition: color 0.2s ease-in-out;
      -moz-transition: color 0.2s ease-in-out;
      -o-transition: color 0.2s ease-in-out;
      -ms-transition: color 0.2s ease-in-out;
      transition: color 0.2s ease-in-out;
      &:active {
        background-color: transparent !important;
      }
    }
    &.current a {
      color: lightsteelblue;
    }
  }
}

/* Links */
