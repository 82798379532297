@import "../../scss/colours";

/* ------------------------------------------------------------------ */
/* c. About Section
/* ------------------------------------------------------------------ */

#about {
  background: #2b2b2b;
  padding-top: 96px;
  padding-bottom: 66px;
  overflow: hidden;
  a {
    color: #fff;
    &:visited {
      color: #fff;
    }
    &:hover,
    &:focus {
      color: $txt-hover;
    }
  }
  h2 {
    font: 22px/30px "opensans-bold", sans-serif;
    color: #fff;
    margin-bottom: 12px;
  }
  p {
    line-height: 30px;
    color: #7a7a7a;
  }
  .profile-pic {
    position: relative;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    border-width: 1px;
    border-style: solid;
    border-color: white;
  }
  .contact-details {
    width: 41.66667%;
  }
  .download {
    width: 58.33333%;
    padding-top: 6px;
  }
  .main-col {
    padding-right: 5%;
  }
  .download .button {
    margin-top: 6px;
    background: #444;
    &:hover {
      background: #fff;
      color: #2b2b2b;
    }
    i {
      margin-right: 15px;
      font-size: 20px;
    }
  }
}
